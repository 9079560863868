<template>
  <div class="json-compression">
    <textarea class="json-input" v-model="inputJson"></textarea>
    <div class="json-view">
      <div class="show-btn">
        <a @click="compress()">压 缩</a>
        <a @click="uncompress()">解 压</a>
        <a @click="copyJson()">复 制</a>
      </div>
      <textarea class="show-json" v-model="showJson" readonly :wrap="wrap"></textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JsonCompression',
  components: {},
  data() {
    return {
      inputJson: '',
      showJson: '',
      wrap: 'no' // off 单行 no 多行
    }
  },
  setup() {
    return {}
  },
  methods: {
    compress() {
      this.wrap = 'off'
      this.showJson = this.inputJson.replace(/\s*/g, '')
    },
    uncompress() {
      this.wrap = 'no'
      try {
        const jsonObj = JSON.parse(this.inputJson)
        this.showJson = JSON.stringify(jsonObj, null, '\t')
        this.$emit('jsonDynamic', this.inputJson, '', true)
      } catch (error) {
        const errorMsg = error.message
        const index = Number(errorMsg.substring(errorMsg.lastIndexOf(' ')))
        this.showJson = this.inputJson.substring(0, index + 1)
        this.$emit(
          'jsonDynamic',
          this.inputJson,
          this.inputJson.substring(index - 20, index + 20),
          false
        )
      }
    },
    copyJson() {
      var textarea = document.createElement('textarea') // 创建input对象
      textarea.value = this.showJson // 设置复制内容
      document.body.appendChild(textarea) // 添加临时实例
      textarea.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(textarea) // 删除临时实例
      this.$message({ type: 'success', message: '成功复制json' })
    }
  },
  computed: {},
  watch: {
    inputJson: function (value) {
      this.inputJson = value
      this.uncompress()
    }
  },
  mounted() {
    const json = this.$parent.$parent.getJsonData()
    if (json != null) {
      this.inputJson = json
    }
  }
}
</script>

<style scoped>
.json-compression {
  height: 100%;
  width: 100%;
  display: grid;
  background-color: #fff;
  grid-template-columns: repeat(2, 50%);
}
.json-input {
  resize: none;
  outline: none;
  color: rgb(87, 87, 87);
  vertical-align: top;
  font-size: 15px;
  letter-spacing: 1px;
  box-sizing: border-box;
  padding: 10px 20px;
  border: 1px solid #eee;
}
.json-view {
  border: 1px solid #eee;
}
.show-btn {
  width: 100%;
  height: 6%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.show-btn a {
  width: 30%;
  height: 70%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #43caff;
  font-style: oblique;
  font-family: monospace, Tahoma, 微软雅黑;
  transition: 0.5s all;
}

.show-btn a:hover {
  background-color: #00b4fc;
  box-shadow: 0 2px 20px -8px rgb(0 0 0 / 50%);
}

.show-json {
  width: 100%;
  height: 94%;
  resize: none;
  outline: none;
  padding: 1%;
  vertical-align: top;
  font-size: 15px;
  letter-spacing: 1px;
  box-sizing: border-box;
  color: rgb(87, 87, 87);
  border-top: 1px solid #eee;
}
</style>
